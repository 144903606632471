.wrapper {
  width: 45px;
  height: 20px;
  position: relative;
  z-index: 1;
}

.circle {
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  background-color: #bdbdbd;
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 0.5s alternate infinite ease;
}

@keyframes circle7124 {
  0% {
    top: 10px;
    height: 1.25px;
    border-radius: 2.5px;
    transform: scaleX(1.7);
  }

  40% {
    height: 5px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
