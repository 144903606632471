/* Transição de entrada */
.userInfo-enter {
  opacity: 0;
  transform: scale(0.9);
}
.userInfo-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 300ms,
    transform 300ms;
}

/* Transição de saída */
.userInfo-exit {
  opacity: 1;
}
.userInfo-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition:
    opacity 300ms,
    transform 300ms;
}

.fade-in {
  animation: fadeInAnimation 1s ease-in-out forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Adicione no seu arquivo CSS */

/* Adicione no seu arquivo CSS */

.background-opacity::before {
  content: "";
  position: absolute;
  inset: 0;
  background: url("/src/assets/default-background.png") no-repeat center center;
  background-size: cover;
  z-index: -1;
  opacity: 0.5; /* Opacidade padrão */
}

.dark .background-opacity::before {
  opacity: 0.2; /* Opacidade mais forte para tema claro */
}
