@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply px-3 py-2 text-sm font-semibold text-white transition duration-300 ease-in-out ring-1 ring-inset ring-indigo-600 bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:rounded-md disabled:dark:bg-[#1d2432] disabled:px-3 disabled:py-2 disabled:text-sm disabled:font-semibold disabled:text-slate-500 disabled:dark:text-slate-200 disabled:shadow-sm disabled:ring-1 disabled:ring-inset disabled:ring-slate-300 disabled:dark:ring-slate-600 disabled:bg-slate-50 disabled:transition disabled:ease-in-out disabled:duration-300 disabled:cursor-not-allowed;
  }
  .radio-label {
    @apply block text-sm font-medium leading-6 cursor-pointer text-slate-900 dark:text-slate-200;
  }
  .radio-button {
    @apply text-indigo-600 bg-defaultWhite border-slate-300 cursor-pointer dark:border-slate-500 dark:bg-[#1d2432] size-4 focus:ring-indigo-600;
  }
  .default-report-input {
    @apply block w-full p-0 border-0 text-slate-900 dark:bg-slate-900 dark:text-slate-200 placeholder:text-slate-400 focus:ring-0 sm:text-sm sm:leading-6;
  }
  .default-report-group {
    @apply rounded-md bg-defaultWhite dark:bg-slate-900 px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600 focus-within:ring-2 focus-within:ring-indigo-600;
  }
  .default-report-input-label {
    @apply block text-xs font-medium text-slate-900 dark:text-slate-200;
  }
  .smooth-all {
    @apply transition-all duration-300 ease-in-out transform-gpu;
  }
  .smooth-colors {
    @apply transition-colors duration-300 ease-in-out transform-gpu;
  }
  .smooth-opacity {
    @apply transition-opacity duration-300 ease-in-out transform-gpu;
  }
  .smooth-transform {
    @apply transition-transform duration-300 ease-in-out transform-gpu;
  }
  .smooth-width {
    @apply duration-300 ease-in-out transform-gpu transition-width;
  }
  .smooth-height {
    @apply duration-300 ease-in-out transform-gpu transition-height;
  }
  .smooth-shadow {
    @apply transition-shadow duration-300 ease-in-out transform-gpu;
  }
  .smooth-border {
    @apply duration-300 ease-in-out transform-gpu transition-border;
  }
  .smooth-border-radius {
    @apply duration-300 ease-in-out transform-gpu transition-border-radius;
  }
  .smooth-background {
    @apply duration-300 ease-in-out transform-gpu transition-background;
  }
  .smooth-fill {
    @apply duration-300 ease-in-out transform-gpu transition-fill;
  }
  .smooth-backdrop-filter {
    @apply fixed inset-0 bg-black bg-opacity-25 backdrop-blur-lg backdrop-filter transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in transform-gpu;
  }
  .smooth-listbox-options {
    @apply absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm smooth-all transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in transform-gpu;
  }
  .default-input {
    @apply block w-full p-0 text-sm bg-white border-0 text-slate-700 dark:text-slate-200 dark:bg-slate-900 placeholder-slate-400 focus:ring-0 sm:text-sm sm:leading-6;
  }
  .default-input-ring {
    @apply rounded-md px-3 pb-1.5 pt-2.5 ring-1 ring-inset bg-white dark:bg-slate-900 ring-slate-300 dark:ring-slate-700 focus-within:ring-2 focus-within:ring-indigo-600 smooth-all;
  }
  .default-input-label {
    @apply block text-xs font-semibold text-slate-700 dark:text-slate-200;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: slatescale;
  background-color: #0b1120;
}

code {
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
/* The scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: rgba(31, 41, 55, 0.8);
  border-radius: 20px;
}

/* The scrollbar thumb (handle) - hover effect */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(31, 41, 55, 1);
}

.textarea-custom-focus:focus {
  outline: none; /* Removes the default focus outline */
  border-color: transparent; /* Makes all borders except bottom transparent */
  border-bottom-color: #7f85f5; /* Sets the bottom border color */
  border-bottom-width: 3px; /* Sets the bottom border width */
}
::selection {
  color: #ffffff; /* Cor do texto */
  background-color: #354266; /* Cor de fundo */
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
